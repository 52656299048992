* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Bicyclette";
  src: url("../font/Bicyclette-Regular.ttf");
}

@font-face {
  font-family: "Bicyclette-Bold";
  src: url("../font/Bicyclette-Bold.ttf");
}

@font-face {
  font-family: "Tajawal";
  src: url("../font/Tajawal-Regular.ttf");
}

@font-face {
  font-family: "Tajawal-ExtraBold";
  src: url("../font/Tajawal-ExtraBold.ttf");
}

html[lang="en"] body,
html[lang="tr"] body {
  font-family: "Bicyclette";
  color: #082756;
  direction: ltr !important;
}

html[lang="ar"] body {
  font-family: "Tajawal";
  color: #082756;
  direction: rtl !important;
}

a,
button {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

a:hover {
  color: #ff6700;
}

html[lang="tr"] .cat_slider .slick-next {
  left: auto !important;
}

html[lang="tr"] .slick-next:before {
  content: "→" !important;
}

html[lang="tr"] .slick-prev:before {
  content: "←" !important;
}

/* style header */

header {
  width: 100%;
  z-index: 1;
  padding: 1rem 0;
}

header .logo {
  width: 200px;
}

header ul li a,
header button {
  text-transform: uppercase;
  color: #082756;
  font-size: 20px;
}

.intro_page video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media (max-width:575px) {
  .intro_page video {
    height: auto;
  }
}

.intro_page img {
  width: 300px;
}

.intro_page .content {
  position: absolute;
  bottom: 4em;
  left: 0;
  width: 100%;
}

@media (max-width:767.5px) {
  .intro_page img {
    width: 100%;
  }
}

@media (max-width:575.5px) {
  .intro_page img {
    width: 100px;
  }

  .intro_page .content {
    bottom: 2em;
  }
}

.intro_page .hidden {
  display: none;
}

.delayed-fade-in {
  animation-name: fade-in-bottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* style homepage */
.main-slider .item {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding: 10rem 0;
  z-index: 1;
  color: #fff;
}

.main-slider .item::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(180deg,
      rgba(65, 154, 255, 0.738) 21%,
      #082756 86%);
  z-index: -1;
}

.main-slider .item1 {
  background-image: url("../img/banner1.jpg");
}

.main-slider .item2 {
  background-image: url("../img/banner2.jpg");
}

.main-slider h1 {
  font-size: 80px;
}

html[lang="en"] .main-slider h1,
html[lang="tr"] .main-slider h1 {
  font-family: "Bicyclette-Bold";
}

html[lang="ar"] .main-slider h1 {
  font-family: "Tajawal-ExtraBold";
}

.main-slider p {
  font-size: 30px;
  margin-bottom: 2rem;
}

html[lang="en"] .main-slider h2,
html[lang="tr"] .main-slider h2 {
  letter-spacing: 4px;
  text-transform: uppercase;
}

html[lang="ar"] .main-slider h2 {
  letter-spacing: 0;
  text-transform: uppercase;
}

.main-slider a {
  font-size: 25px;
}

.main-slider .slick-dots {
  bottom: 8em;
}

.main-slider .slick-dots li {
  margin: 0 !important;
}

.main-slider .slick-dots li button:before {
  content: "" !important;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 13px !important;
  height: 13px !important;
  opacity: 1;
}

.main-slider .slick-dots li.slick-active button:before {
  background: #fff;
  opacity: 1;
}

.main-slider .btn-group a {
  font-size: 20px;
  border-radius: 50px;
  background: #ff6700;
  padding: 20px 40px;
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #ff6700;
}

.main-slider .btn-group a:last-child {
  background: transparent;
  margin-left: 2rem;
}

.sub-title {
  text-transform: uppercase;

  color: #ff6700;

  font-size: 20px;
  margin-bottom: 1rem;
}

html[lang="en"] .sub-title,
html[lang="tr"] .sub-title {
  font-family: "Bicyclette-Bold";
  letter-spacing: 2px;
}

html[lang="ar"] .sub-title {
  font-family: "Tajawal-ExtraBold";
}

.title {
  font-size: 50px;
  line-height: 40px;
  margin-bottom: 2rem;
}

html[lang="en"] .title,
html[lang="tr"] .title {
  font-family: "Bicyclette-Bold";
  letter-spacing: 2px;
}

html[lang="ar"] .title {
  font-family: "Tajawal-ExtraBold";
}

.desc {
  font-size: 23px;
}

.about-section {
  padding: 5rem 0;
}

.about-section a {
  text-transform: capitalize;
  font-size: 20px;
  border: 2px solid #ff6700;
  border-radius: 50px;
  padding: 15px 40px;
  color: #082756;
  font-weight: bold;
}

.about-section a:hover,
.main-slider .btn-group a:hover {
  background: #ff6700;
  color: #fff;
}

.serv-section {
  background: #f4f6f9;
  padding: 4rem 0;
  position: relative;
  z-index: 1;
}

.serv-section .left-shape {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 300px;
}

.serv-section .box {
  background: #fff;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.serv-section .box:hover {
  filter: drop-shadow(-1.395px 19.951px 5px rgba(136, 136, 136, 0.1));
  transform: translateY(-5px);
}

.serv-section .box:hover .icon img {
  webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.serv-section .icon img {
  width: 70px;
  transition: all 0.5s ease;
  margin-bottom: 1rem;
}

.about-section .title {
  line-height: 45px;
  font-size: 40px;
}

.serv_name {
  font-size: 32px;

  text-transform: capitalize;
}

html[lang="en"] .serv_name,
html[lang="tr"] .serv_name {
  font-family: "Bicyclette-Bold";
}

html[lang="ar"] .serv_name {
  font-family: "Tajawal-ExtraBold";
  text-align: right;
  font-size: 25px;
}

.serv-section .box a,
.cat_slider a {
  font-size: 20px;
  text-transform: capitalize;
  color: #ff6700;
}

.serv-section .box:hover a,
.cat_slider .box:hover a {
  color: #ff6700;
}

.serv-section {
  background-image: url("../img/1.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 7rem 0;
}

.serv-section:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(180deg,
      rgb(65 155 255 / 80%) 21%,
      #155bc6 86%);
}

.cat_slider .box {
  background: #fff;
  margin: 0 1rem;
  padding-bottom: 2rem;
  overflow: hidden;
  box-shadow: 0 0 11px 10px rgba(136, 136, 136, 0.1);
}

.cat_slider .box img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 1.5rem;
  transition: all 0.9s ease;
}

.cat_slider .box:hover img {
  transform: scale(1.1);
}

.cat_slider .serv_name,
.cat_slider .desc,
.cat_slider a {
  padding: 0 2rem;
}

.cat_slider .desc {
  height: 160px;
  margin-bottom: 1rem;
}

.contact-info a {
  text-transform: lowercase;
}

html[lang="en"] .cat_slider .box,
html[lang="tr"] .cat_slider .box {
  text-align: left;
}

html[lang="ar"] .cat_slider .box {
  text-align: right;
}

.cat_slider .serv_name {
  font-size: 35px;
}

html[lang="tr"] .cat_slider .serv_name {
  font-size: 27px;
}

.cat_slider .slick-prev,
.cat_slider .slick-next {
  position: absolute;
  top: -100px;
  background: #ff6700;
  padding: 0;
  width: 50px;
  height: 50px;
}

html[lang="en"] .cat_slider .slick-prev,
html[lang="tr"] .cat_slider .slick-prev {
  left: auto;
  right: 60px;
}

html[lang="ar"] .cat_slider .slick-prev {
  right: auto;
  left: 60px;
}

html[lang="en"] .cat_slider .slick-next,
html[lang="tr"] .cat_slider .slick-next {
  right: 0;
}

html[lang="ar"] .cat_slider .slick-next {
  left: 0;
}

html[lang="en"] .row_num,
html[lang="tr"] .row_num {
  flex-direction: row;
}

html[lang="ar"] .row_num {
  flex-direction: row-reverse;
}

.slick-prev:before,
.slick-next:before {
  font-family: math !important;
  opacity: 1;
  font-size: 30px;
}

.category-section {
  padding: 6rem 0;
}

.category-section .title,
.logos .title {
  color: #ff6700;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: #ff6700 !important;
}

.number_section h4 {
  text-transform: uppercase;
  font-size: 20px;
}

.number_section .num {
  font-family: "Bicyclette-Bold";
  font-size: 40px;
}

.number_section {
  background-image: url("../img/11.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  background-position: center;
  padding: 8rem 0;
}

.number_section::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg,
      rgb(65 155 255 / 0%) 21%,
      #419aff1a 86%);
  z-index: -1;
}

.number_section .icon {
  background: #ff6700;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
  padding: 10px 0;
}

.number_section .icon img {
  width: 70px;
  filter: brightness(0) invert(1);
}

.logos img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

footer {
  background: #ff6700;
  padding: 5rem 0 2rem;
  position: relative;
}

footer .logo {
  width: 250px;
  filter: brightness(0) invert(1);
}

footer h2 {
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

footer a,
.col-right a,
.col-right li {
  font-size: 20px;
  line-height: 2;
  color: #fff;
  text-transform: capitalize;
}

html[lang="en"] footer a i,
html[lang="en"] .col-right i,
html[lang="tr"] footer a i,
html[lang="tr"] .col-right i {
  padding-right: 10px;
}

html[lang="ar"] footer a i,
html[lang="ar"] .col-right i {
  padding-left: 10px;
}

footer a:hover {
  color: #fff;
}

footer .social {
  display: flex;
  justify-content: space-between;
}

footer .social i {
  font-size: 25px;
}

.bottom-footer {
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding-top: 2rem;
}

footer .left_shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 240px;
}

footer .right_shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
}

.page .banner {
  background-size: cover;
  padding: 8rem 0;
  height: 400px;
  text-align: center;
  color: #fff;
  background-position: center;
  position: relative;
  z-index: 1;
  margin-bottom: 7rem;
}

.about_page .banner {
  background-image: url("../img/about-banner.png");
}

.serv_page .banner {
  background-image: url("../img/1.jpg");
}

.contact_page .banner {
  background-image: url("../img/laptop-medical-equipment.jpg");
}

.title_page {
  font-size: 80px;
  text-transform: uppercase;
}

html[lang="en"] .title_page,
html[lang="tr"] .title_page {
  font-family: "Bicyclette-Bold";
}

html[lang="ar"] .title_page {
  font-family: "Tajawal-ExtraBold";
}

.page .banner::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(180deg,
      rgba(65, 154, 255, 0.738) 21%,
      #08275682 86%);
  z-index: -1;
}

.about_page .title {
  color: #ff6700;
}

.list_about {
  margin-top: 4rem;
}

.list_about h2 {
  color: #fff;
}

.list_about h1 {
  font-family: "Bicyclette-Bold";
  font-size: 90px;
  position: absolute;
  left: 0;
  top: -50px;
  color: #082756;
}

.about_page img {
  height: 450px;
  object-fit: cover;
}

.list_about .col-lg-6 .content {
  background: #419bff;
  padding: 3rem 1.5rem;
  height: 300px;
}

.list_about .col-lg-6:nth-child(2) .content {
  background: #ff6700;
}

.list_about .col-lg-6:nth-child(3) .content {
  background: #ff6700;
}

.list_about p {
  color: #fff;
  text-transform: capitalize;
}

.about_page .bg_section {
  background-position: center;
  background-size: cover;
  background-image: url("../img/4\ \(1\).jpg");
  padding: 5rem 0;
  height: 500px;
  position: relative;
  z-index: 1;
}

.about_page .bg_section::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 500px;
  background-color: #082756d3;
  z-index: -1;
}

.row-value h3 {
  padding: 10px 0;
  position: relative;
}

.row-value h3::before {
  position: absolute;
  content: "";
  top: -5px;
  background: #ff6700;
  width: 300px;
  height: 3px;
}

html[lang="en"] .row-value h3::before {
  left: 0;
}

html[lang="ar"] .row-value h3::before {
  right: 0;
}

.row-value {
  padding: 5rem 0;
}

.about_page .row-flex {
  padding: 4rem 0;
}

.serv_page .serv_name {
  text-transform: uppercase;
  color: #ff6700;
}

.serv_page .container .row:nth-child(2n + 1) {
  flex-direction: row-reverse;
}

.serv_page .container .row {
  margin-bottom: 7rem;
}

.serv_page img {
  height: 400px;
  object-fit: cover;
}

.contact_page .box {
  box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.1);
  background: #fff;
  position: relative;
  top: -5em;
}

.contact_page .box .content {
  padding: 60px;
}

.col-right {
  background-image: url("../img/1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 4rem 2rem;
  color: #fff;
  border-left: 7px solid #ff6700;
}

.col-right:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(180deg,
      rgb(65 155 255 / 80%) 21%,
      #155bc6 86%);
}

.col-right .title {
  font-size: 30px;
  margin-bottom: 20px !important;
}

.col-right h3 {
  font-size: 24px;
  position: relative;
}

html[lang="en"] .col-right h3,
html[lang="tr"] .col-right h3 {
  padding-left: 20px;
}

html[lang="en"] .col-right h3::before,
html[lang="tr"] .col-right h3::before {
  left: 0;
}

html[lang="ar"] .col-right h3::before {
  right: 0;
}

html[lang="ar"] .col-right h3 {
  padding-right: 20px;
}

.col-right h3::before {
  content: "";
  position: absolute;

  top: 8px;
  width: 8px;
  height: 8px;
  background: #ff6700;
}

.col-right ul {
  padding-top: 8rem;
}

.MuiFormLabel-root {
  color: rgb(8 39 86) !important;
}

html[lang="en"] .MuiFormLabel-root,
html[lang="tr"] .MuiFormLabel-root {
  font-family: "Bicyclette" !important;
}

html[lang="ar"] .MuiFormLabel-root {
  font-family: "Tajawal" !important;
}

.MuiFormControl-root {
  margin-bottom: 1rem !important;
}

.MuiInputBase-root {
  border: 2px solid rgba(226, 222, 222, 0.567);
  background-color: transparent !important;
  border-radius: 0 !important;
}

.MuiInputBase-root::before {
  display: none !important;
}

.MuiInputBase-root::after {
  border-bottom: 2px solid #ff6700 !important;
}

.btn-submit {
  font-size: 25px;
  background: #ff6700;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  width: 100%;
  border-radius: 0;
}

.btn-submit:focus {
  box-shadow: none;
}

.btn-submit:hover {
  color: #fff;
  background: #419bff;
}

/* style sidebar */
.burger {
  display: none;
  color: #ff6700;
  font-size: 22px;
  cursor: pointer;
  position: relative;
  top: 10px;
}

html[lang="en"] .burger,
html[lang="tr"] .burger {
  text-align: right;
}

html[lang="ar"] .burger {
  text-align: left;
}

.icon-close {
  color: #ff6700;
  font-size: 22px;
  position: absolute;
  left: 30px;
  top: 30px;
  cursor: pointer;
}

.nav-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 300px;
  background-color: #fff;
  box-shadow: -2px 2px 6px 0 rgb(151 163 182);
  overflow-x: hidden;
  transition: 0.5s;
}

html[lang="en"] .nav-menu,
html[lang="tr"] .nav-menu {
  right: -100%;
}

html[lang="ar"] .nav-menu {
  left: -100%;
}

html[lang="en"] .nav-menu.active,
html[lang="tr"] .nav-menu.active {
  right: 0;
  transition: 0.5s;
}

html[lang="ar"] .nav-menu.active {
  left: 0;
  transition: 0.5s;
}

.nav-menu ul {
  margin-top: 5rem;
}

.nav-menu a {
  line-height: 50px;
}

.serv-section .desc {
  height: 175px;
}

/* reponsive */
@media (min-width: 992px) and (max-width: 1199px) {
  .list_about .col-lg-6 .content {
    height: 400px;
  }

  .serv-section .col-lg-4 {
    width: 50%;
  }

  .logos img {
    width: 100%;
  }
}

@media (max-width: 991.5px) {
  iframe {
    height: 400px;
  }

  .serv_page img {
    height: 250px;
    margin-top: 2rem;
  }

  .serv_page .container .row {
    margin-bottom: 3rem;
  }

  .row-revers {
    flex-direction: column-reverse;
  }

  .about_page .row-flex {
    padding: 0 0 4rem;
  }

  .row-value {
    padding: 0 0 4rem;
  }

  .about_page .title {
    text-align: left !important;
  }

  .about_page img {
    height: 200px;
  }

  .list_about .col-lg-6:nth-child(3) .content {
    background: #419bff;
  }

  .list_about .col-lg-6:nth-child(4) .content {
    background: #ff6700;
  }

  footer a,
  .col-right a,
  .col-right li {
    font-size: 18px;
  }

  footer .logo {
    margin-bottom: 2rem;
  }

  footer .left_shape {
    width: 125px;
  }

  .burger {
    display: block;
  }

  .main-slider h1 {
    font-size: 60px;
  }

  .main-slider p {
    font-size: 25px;
  }

  .main-slider .item {
    padding: 5rem 0;
  }

  .main-slider h2 {
    font-size: 20px;
  }

  .about-section img {
    margin-top: 3rem;
  }

  .serv-section .desc,
  .cat_slider .desc {
    font-size: 20px;
  }

  .serv-section .serv_name,
  .cat_slider .serv_name {
    font-size: 27px;
  }

  .number_section .num,
  .number_section h4 {
    color: #fff;
  }

  .number_section {
    padding: 5rem 0 0;
    background-blend-mode: overlay;
    background-color: #122139;
    background-position: inherit;
  }

  .logos img {
    width: 100%;
    height: 160px;
    margin-bottom: 2rem;
  }
}

@media (max-width: 767.5px) {
  .cat_slider .desc {
    height: auto;
    margin-bottom: 1rem;
  }

  .main-slider h1 {
    font-size: 50px;
  }

  .main-slider p,
  .desc {
    font-size: 20px;
  }

  .main-slider .btn-group a {
    padding: 15px 25px;
  }

  .title {
    font-size: 40px;
    margin-bottom: 1rem;
  }

  .serv-section {
    padding: 3rem 0;
  }

  .serv-section .title {
    margin-bottom: 2rem !important;
  }

  .cat_slider .slick-prev {
    right: 50px;
  }

  .cat_slider .slick-prev,
  .cat_slider .slick-next {
    width: 40px;
    height: 40px;
    top: -40px;
  }

  .serv-section .desc {
    height: auto;
  }
}

@media (max-width: 575.5px) {
  html[lang="ar"] footer .right_shape {
    display: none !important;
  }

  html[lang="en"] footer .right_shape,
  html[lang="tr"] footer .right_shape {
    display: block !important;
  }

  .btn-submit {
    font-size: 18px;
  }

  .col-right ul {
    padding-top: 2rem;
  }

  .contact_page .box .content {
    padding: 60px 30px;
  }

  .contact_page .box {
    top: 0;
  }

  .page .banner {
    padding: 4rem 0;
    height: 200px;
    margin-bottom: 3rem;
  }

  .page .banner::after {
    height: 200px;
  }

  .main-slider .slick-dots {
    bottom: 10px;
  }

  .main-slider .item,
  .main-slider .item::after {
    height: 550px;
  }

  .logos {
    padding: 0 !important;
  }

  footer .right_shape {
    width: 120px;
  }

  footer {
    padding: 2rem 0;
  }

  .main-slider h1,
  .title_page {
    font-size: 35px;
  }

  .list_about .col-lg-6 .content {
    height: auto;
    padding: 3rem 1rem 1rem;
  }

  .btn-group {
    display: grid;
  }

  .main-slider .btn-group a:last-child {
    margin: 1rem 0 0;
  }

  .main-slider h2 {
    font-size: 17px;
  }

  .main-slider .btn-group a {
    padding: 12px 25px;
    font-size: 18px;
  }

  .title {
    font-size: 35px;
  }

  .serv-section .left-shape {
    width: 200px;
  }

  .cat_slider .slick-prev,
  .cat_slider .slick-next {
    width: 30px;
    height: 30px;
    top: 250px;
    z-index: 1;
  }

  html[lang="en"] .cat_slider .slick-prev,
  html[lang="tr"] .cat_slider .slick-prev {
    right: auto;
    left: 0;
  }

  html[lang="ar"] .cat_slider .slick-prev {
    left: auto;
    right: 0;
  }
}

.content {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 4s ease, transform 1s ease;
}

.delayed-fade-in {
  opacity: 1;
  transform: scale(1);
}

.content img:hover {
  transform: scale(1.1);
  /* تكبير الحجم عند مرور المؤشر */
  transition: transform 0.3s ease;
  /* تأثير الانتقال لسلاسة الحركة */
}

.intro_page video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.content img {
  max-width: 100%;
  height: auto;
}


